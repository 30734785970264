import React from "react"

import Layout from '../../components/layout'
import Head from '../../components/head'
import calcStyles from './calculators.module.scss'

import OddsCalc from "../../components/Calculators/odds_calculator"
import CalculatorDrop from "../../components/Title Drop/calculator_drop"


const OddsCalculator = () => {

    return (
        <Layout>
            <Head
                title="Free NFL Odds Calculator"
                pageDescription="A free tool for bettors to convert odds between formats and calculate the payouts of their wagers"
            />
                <CalculatorDrop
                    startingItem={'NFL Odds Calculator'}
                />
                <p className={calcStyles.calculator_subtitle}>
                    A free tool for bettors to convert odds between formats and calculate the payouts of their wagers
                </p>
                <OddsCalc/>
                <div className={calcStyles.content_container}>
                    <h3 className={calcStyles.content_h3}>
                        Overview
                    </h3>
                    <p className={calcStyles.content_p}>
                        Need to know how to convert American odds to decimal odds? Or decimal odds to fractional odds? Or maybe you want to know what percent of your bets you need to win to break-even? Or maybe even you want to know something specific, like how much a $5000 bet at 8/1 odds would payout? No problem - our free NFL odds calculator can handle all of that.<br className={calcStyles.content_break}/>
                        To use the calculator, Simply input odds and wager amounts into the tool and it will automatically convert your odds and calculate your bet’s payout, making it easy to see how the different formats are related and how much you could win.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        Why are there multiple betting odds formats?
                    </h3>
                    <p className={calcStyles.content_p}>
                        No matter what format you’re using, all odds formats convert to the same value. Odds formats are simply different ways of writing the same number, much in the same way the fraction ½ is just another way to write 50%.<br className={calcStyles.content_break}/>
                        We have different odds formats primarily due to regional and social convention. NFL and American sports markets typically express bets as the amount you’d need to wager to win $100, while European markets express bets as the payout multiple of your wager, and UK markets express odds as a payout ratio or fraction. Though they look quite different, all these odds formats always translate to the same implied win probability and payout structure. To help better understand these odds, let’s walk through some examples.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        American Odds
                    </h3>
                    <p className={calcStyles.content_p}>
                        American odds are the amount of money you’d need to risk, in order to win $100 of profit. If the odds are negative, for instance $-110, wagering $110 dollars would generate a payout of $210 and a profit of $100. If the odds are positive, it means you’d need to wager less than $100 dollars to win $100. For instance, a $90 bet with +$110 odds would generate a payout of $190 and a profit of $100.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        Decimal Odds
                    </h3>
                    <p className={calcStyles.content_p}>
                        Decimal odds are, perhaps, the easiest odds to translate into a payout. To calculate a payout from decimal odds, simply multiple the wager amount by the decimal odds. For instance, a $100 bet with 2.1 odds would generate a payout of $210 dollars and a profit of $110.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        Fractional Odds
                    </h3>
                    <p className={calcStyles.content_p}>
                        If decimal odds are the easiest odds to translate into a payout, fractional odds are the hardest. To convert fractional odds into a payout, you first multiply the wagered amount by the top number, and then divide it by the bottom number. So if you waged $100 at 5/4 odds, you’d first multiply $100 by 5 to get $500, and then divide it by 4 to get the payout of $125.
                    </p>
                    <h3 className={calcStyles.content_h3}>
                        Implied Win %
                    </h3>
                    <p className={calcStyles.content_p}>
                        While not an odds format, the implied win percentage is a helpful way of thinking about the odds you’re wagering. The implied win percent is the percent of wagers you’d need to win in order to break even on the odds you received. For instance, on a $100 bet that pays out $200, you’d need to win at least 50% of your bets to break even. If you don’t think your bet has at least a 50% chance of winning, then you shouldn’t place it.
                    </p>
                </div>
        </Layout>
    )

}

export default OddsCalculator