import React, { useState } from "react"

import calcStyles from './odds_calculator.module.scss'

const OddsCalc = () => {

    // set initial states //
    const [amtRisked, setAmtRisked] = useState(100)
    const [amtToWin, setAmtToWin] = useState('$200')
    const [payoutVisible, setPayoutVisible] = useState(true)
    const [profit, setProfit] = useState('$100')
    const [americanOdds, setAmericanOdds] = useState(100)
    const [decimalOdds, setDecimalOdds] = useState(2)
    const [fractionalOdds, setFractionalOdds] = useState('1 / 1')
    const [impliedOdds, setImpliedOdds] = useState('50%')
    const [alertMsg, setAlertMessage] = useState("")
    const [alertVisible, setAlertVisible] = useState(false)

    // func for creating fractions //
    function fracReduce(numerator,denominator){
        var gcd = function gcd(a,b){
            return b ? gcd(b, a%b) : a;
        };
        gcd = gcd(truncate(numerator + .00001,0),denominator);
        return [truncate(numerator + .00001,0)/gcd, denominator/gcd];
    }

    // func for imperceisely handling float-point rounding hell //
    function truncate (num, places) {
        return Math.trunc(num * Math.pow(10, places)) / Math.pow(10, places);
    }

    // update text entries when value is wrong //
    // active field gets raw entry text, all others are wiped //
    const updateWithError = (activeEntry, rawEntry) => {

        // set pct //
        if (activeEntry !== 'pct') {
            setImpliedOdds('')
        } else {
            setImpliedOdds(
                rawEntry
            )
        }

        // set odds //
        // decimal //
        if (activeEntry !== 'decimal') {
            setDecimalOdds('')
        } else {
            setDecimalOdds(
                rawEntry
            )
        }
        
        // set american //
        if (activeEntry !== 'american') {
            setAmericanOdds('')
        } else {
            setAmericanOdds(
                rawEntry
            )
        }

        // fraction //
        if (activeEntry !== 'frac') {
            setFractionalOdds('')
        } else {
            setFractionalOdds(
                rawEntry
            )
        }

        // payout //
        setProfit('--')
        setAmtToWin('--')
        setPayoutVisible(false)

        // make alert visible //
        setAlertVisible(true)

    }

    // universal updator given implied win prob //
    // active entry makes sure we dont update what user is typing //
    // since we are rounding //
    const updateOddsValues = (pct, activeEntry, rawEntry) => {
        
        // clear any errors, unless invalid entry in risked //
        if (amtRisked === '') {
            setAlertMessage('')
            setAlertVisible(false)
        } else if (isNaN(amtRisked) || (amtRisked < 0)) {
            setAlertMessage('Bet amount must be a number greater than 0')
            setAlertVisible(true) 
        } else {
            setAlertMessage('')
            setAlertVisible(false)
        }

        // set pct //
        if (activeEntry !== 'pct') {
            setImpliedOdds(`${truncate(100 * pct, 2)}%`)
        } else {
            setImpliedOdds(
                rawEntry
            )
        }

        // set odds //
        // decimal //
        if (activeEntry !== 'decimal') {
            setDecimalOdds(
                truncate((1/pct), 4)
            )
        } else {
            setDecimalOdds(
                rawEntry
            )
        }
        
        // set american //
        if (activeEntry !== 'american') {
            if (pct > .5) {
                setAmericanOdds(
                    (-1 * ((100 * pct) / (1-pct))).toFixed(1)
                )
            } else {
                setAmericanOdds(
                    ((100 - 100 * pct) / pct).toFixed(1)
                )
            }
        } else {
            setAmericanOdds(
                rawEntry
            )
        }

        // fraction //
        if (activeEntry !== 'frac') {
            const nd = fracReduce(
                10000*((1/pct) -1),
                10000
            )
            setFractionalOdds(
                `${nd[0]} / ${nd[1]}`
            )
        } else {
            setFractionalOdds(
                rawEntry
            )
        }

        // payout //
        if (isNaN(amtRisked) || (amtRisked === '')) {
            setProfit('--')
            setAmtToWin('--')
            setPayoutVisible(false)
        } else {
            setPayoutVisible(true)
            setAmtToWin(
                `$${truncate(
                    amtRisked / pct,
                    2
                )}`
            )
            setProfit(
                `$${truncate(
                    amtRisked / pct -
                    amtRisked,
                    2
                )}`
            )
        }
    }

    // formula to update risked amount //
    const updateRisked = event => {
        
        const risked = event.target.value
        setAmtRisked(risked)

        if (risked === '') {
            setAlertVisible(false)
            setProfit('--')
            setAmtToWin('--')
            setPayoutVisible(false)
        } else if (isNaN(risked) || (risked <= 0)) {
            setProfit('--')
            setAmtToWin('--')
            setPayoutVisible(false)
            setAlertMessage('Bet amount must be a number greater than 0')
            setAlertVisible(true)
        } else {
            setAlertVisible(false)
            if (isNaN(decimalOdds) || decimalOdds === '') {
                setProfit('--')
                setAmtToWin('--')
                setPayoutVisible(false)
            } else {
                setPayoutVisible(true)
                setAmtToWin(
                    `$${truncate(
                        risked * decimalOdds,
                        2
                    )}`
                )
                setProfit(
                    `$${truncate(
                        risked * decimalOdds -
                        risked,
                        2
                    )}`
                )
            }
        }
    }

    // formulas to update odds //
    // american //
    const updateAmerican = event => {

        const odds = event.target.value
        const americanFormatted = parseFloat(odds).toFixed(1)
        
        if (isNaN(americanFormatted) || (americanFormatted < 100 & americanFormatted >-100)) {
            // alert msg if odds not valid //
            setAlertMessage('American Odds must be greater than 100 or less than -100')
            updateWithError('american', odds)
        } else {
            // if odds valid, convert to pct and update all //
            if (americanFormatted < 0) {
                updateOddsValues(
                    (-1 * americanFormatted) / (-1 * americanFormatted + 100),
                    'american',
                    odds
                )
            } else {
                updateOddsValues(
                    (100 / (parseFloat(americanFormatted) + 100)),
                    'american',
                    odds
                )
            }

        }

    }

    // decimal //
    const updateDecimal = event => {

        const odds = event.target.value
        const decimalFormatted = parseFloat(odds).toFixed(3)

        if (isNaN(decimalFormatted) || decimalFormatted <=1) {
            // alert msg if odds not valid //
            setAlertMessage('Decimal Odds must be greater than 1')
            updateWithError('decimal', odds)
        } else {
            // if odds valid, convert to pct and update all //
            updateOddsValues(
                1 / decimalFormatted,
                'decimal',
                odds
            )
        
        }   

    }

    const updateFrac = event => {

        const odds = event.target.value
        const fracArray = odds.split('/')

        if (fracArray.length !== 2) {
            // alert msg if odds not valid //
            setAlertMessage('Fractional Odds must contain a single /')
            updateWithError('frac', odds)
        } else {

            const numerator = parseInt(fracArray[0].trim())
            const denominator = parseInt(fracArray[1].trim())
    
            if (isNaN(numerator) || isNaN(denominator)) {
                // alert msg if odds not valid //
                setAlertMessage('Fractional Odd numerator and denominator must be integers')
                updateWithError('frac', odds)
            } else {
                updateOddsValues(
                    1 / (1 + numerator/denominator),
                    'frac',
                    odds
                )
            }

        } 

    }

    const updatePct = event => {

        const odds = event.target.value
        const rawPct = parseFloat(odds.split('%')[0])

        if (isNaN(rawPct)) {
             // alert msg if odds not valid //
             setAlertMessage('Implied percent odds must be a number')
             updateWithError('pct', odds)
        
        } else if (rawPct === 0) {
            setAlertMessage('Implied percent odds must be greater than 0')
            updateWithError('pct', odds)
        } else if (rawPct < 0) {
            setAlertMessage('Implied percent odds must be positive')
            updateWithError('pct', odds)
        } else if (rawPct >= 1) {
            if (rawPct >= 100) {
                setAlertMessage('Implied percent odds must less than 100%') 
                updateWithError('pct', odds)
            } else {
                updateOddsValues(
                    rawPct / 100,
                    'pct',
                    odds
                )
            }
        } else {
            updateOddsValues(rawPct, 'pct', odds)
        }

    }

    
    return (

        <div className={calcStyles.calc_wrapper}>
            <div className={calcStyles.calc_container}>
                <div className={calcStyles.panel}>
                    <div className={calcStyles.left_container}>
                        <h2 className={calcStyles.title}>
                            Bet Amount <span className={calcStyles.optional_span}>optional</span>
                        </h2>
                        <h3 className={calcStyles.subtitle}>
                            Enter the amount of money placed on the bet 
                        </h3>
                        <div className={calcStyles.left_input_container}>
                            <div className={calcStyles.left_input_dollar_div}>
                                $
                            </div>
                            <input className={calcStyles.left_input_value}
                            id={'risked'}
                            name={'risked'}
                            type="text"
                            value={amtRisked}
                            onChange={updateRisked}
                            />
                        </div>
                    </div>
                    <div className={calcStyles.left_container}>
                        <h2 className={calcStyles.title}>
                            Odds
                        </h2>
                        <h3 className={calcStyles.subtitle}>
                            Enter the American Odds (ie -110 or 110)
                        </h3>
                        <div className={calcStyles.left_input_container}>
                            <div className={calcStyles.left_input_dollar_div}>
                                $
                            </div>
                            <input className={calcStyles.left_input_value}
                                id={'american'}
                                name={'american'}
                                type="text"
                                value={americanOdds}
                                onChange={updateAmerican}
                            />
                        </div>
                    </div>
                </div>
                <div className={calcStyles.panel}>
                    <div className={calcStyles.payout_container_wrapper}>
                        <div className={calcStyles.payout_container}>
                            <h2 className={calcStyles.title}>
                                Payout
                            </h2>
                            <h3 className={calcStyles.subtitle_right}>
                                Total payout based on bet and odds
                            </h3>
                            <p className={calcStyles.payout_value} data-active={payoutVisible}>
                                {amtToWin} 
                            </p>
                        </div>
                        <div className={calcStyles.detail_container_main}>
                            <div className={calcStyles.detail_container_sub}>
                                <p className={calcStyles.detail_container_sub_title}>
                                    Profit    
                                </p>
                                <p className={calcStyles.detail_container_sub_value}>
                                    {profit} 
                                </p>
                            </div>
                            <div className={calcStyles.detail_container_sub}>
                                <p className={calcStyles.detail_container_sub_title}>
                                    Implied %  
                                </p>
                                <input className={calcStyles.detail_container_sub_input}
                                    id={'pct'}
                                    name={'pct'}
                                    type="text"
                                    value={impliedOdds}
                                    onChange={updatePct}
                                />
                            </div>
                            <div className={calcStyles.detail_container_sub}>
                                <p className={calcStyles.detail_container_sub_title}>
                                    Decimal
                                </p>
                                <input className={calcStyles.detail_container_sub_input}
                                    id={'decimal'}
                                    name={'decimal'}
                                    type="text"
                                    value={decimalOdds}
                                    onChange={updateDecimal}
                                />
                            </div>
                            <div className={calcStyles.detail_container_sub}>
                                <p className={calcStyles.detail_container_sub_title}>
                                    Fractional 
                                </p>
                                <input className={calcStyles.detail_container_sub_input}
                                    id={'frac'}
                                    name={'frac'}
                                    type="text"
                                    value={fractionalOdds}
                                    onChange={updateFrac}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={calcStyles.alert_container} data-active={alertVisible}>
                    <p className={calcStyles.alert} data-active={alertVisible}>
                        <span className={calcStyles.alert_span}>&#8855; </span> {alertMsg}
                    </p>
                </div>
            </div>
        </div>

    )
}

export default OddsCalc